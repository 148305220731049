.containerGridCalendar {
	overflow: hidden;
	height: 100vh;
}

.containerFullcalendar {
	overflow: auto;
	height: 100vh;
}

.btn-day-header {
	/* border: 0; */
	width: 100%;
	background-color: red;
}

.mainContent {
	display: block;
	justify-items: center;
}

.doteMarginRight {
	margin-right: 2px;
}

.doteMarginLeft {
	margin-left: 2px;
}

.fc-timeGridDay-view td,
.fc-timeGridDay-view th {
	border: 0px none;
}

.fc-timeGrid3Days-view td,
.fc-timeGrid3Days-view th,
.fc-timeGridWeek-view td,
.fc-timeGridWeek-view th {
	border-top: 0px none;
	border-bottom: 0px none;
}

.fc-content {
	padding: 0px 2px;
	/* height: 90%; */
}

.fc-day-grid-event.fc-selected.fc-event .fc-content {
	padding: 0px 0px;
	height: 100%;
}

.fc-day-grid-event.fc-draggable.fc-event .fc-content .fc-title {
	font-size: 10px;
	line-height: 15px;
	font-weight: normal;
	overflow: hidden;
}

.fc-time {
	text-align: center !important;
	font-size: 13px;
}

.fc-now-indicator {
	border: 0.5px solid #3a465b;
}

.addIndicator {
	border-width: 5px 0 5px 6px;
	border-top-color: transparent;
	border-bottom-color: transparent;
}

.textTagStatus {
	color: #fff;
}

.backgroundTotalTask {
	background-color: #7489ae;
}

.fc-title {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: pre;
}

.fc-title-2 {
	overflow: hidden;
	overflow-wrap: break-word;
}

.fc-title-month {
	white-space: nowrap;
	text-overflow: unset;
	overflow: hidden;
	white-space: pre;
}

.week-day {
	color: #c1c9d4;
	font-weight: normal;
	text-align: center;
	font-size: 12px;
	line-height: 18px;
}

.textDayCurrent {
	color: #464b55;
	font-weight: normal;
	text-align: center;
	font-size: 12px;
}
.text-date-number {
	color: #464b55;
	font-weight: normal;
	text-align: center;
	font-size: 18px;
	font-weight: 600;
	line-height: 20px;
}

.active-date {
	color: #3075ea;
}

.dots-wrapper {
	height: 10px;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.backgroundTotalTask {
	height: 20px;
	display: inline-flex;
	padding: 4px 8px;
	flex-grow: 0;
	font-size: 11px;
	align-items: center;
	flex-shrink: 0;
	line-height: 13px;
	white-space: nowrap;
	text-transform: none;
	border-radius: 4px;
	justify-content: flex-start;
}

.mainHeader {
	display: flex;
	flex-direction: column;
	margin: 4px -0.5px;
	margin-bottom: 0;
	text-align: center;
	justify-content: center;
	align-items: center;
	border: none;
}

.titleDay {
	display: flex;
	flex-direction: column;
}

.fc-time span {
	color: #7489ae;
	font-weight: normal;
	text-align: center;
}

#calendar {
	font-family: 'Inter', sans-serif;
}

.dot {
	height: 4px;
	width: 4px;
	background-color: #7489ae;
	/* background-color: red; */
	border-radius: 50%;
	margin-left: 1px;
	margin-right: 1px;
	/* display: inline-block; */
	/* vertical-align: middle; */
}

.dotContents {
	margin-top: -5px;
	visibility: visible;
	display: block;
}

.virtualDot {
	height: 3px;
	width: 3px;
	background-color: transparent;
	border-radius: 50%;
	display: inline-block;
	vertical-align: middle;
}

.fc-divider {
	height: 0;
	margin: 0;
	padding: 0 !important;
	border: 1px solid #7489ae !important;
}

.fc-bootstrap hr.fc-divider {
	border-color: #7489ae;
}

.currentDay {
	background-color: rgba(255, 187, 0, 0.1);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	min-height: 52px;
	display: flex;
	align-items: center;
	width: 98%;
	flex-direction: column;
	/* padding: auto; */
}

.notCurrentDay {
	background-color: #fff;
	min-height: 52px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	align-items: center;
	justify-content: center;
}

.week-day-active {
	color: #232426;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 600;
}

.fc-day-number {
	width: 100%;
	justify-content: center;
}

.fc-day-top {
	text-align: center !important;
}

.fc-toolbar.fc-header-toolbar {
	margin-bottom: 0px !important;
}

.fc-timeGridDay-view tr td,
.fc-timeGrid3Days-view tr td,
.fc-timeGrid4Days-view tr td,
.fc-timeGridWeek-view tr td {
	border-top: 1px solid #ddd;
	border-left: none !important;
	border-bottom: none;
}

.fc-timeGridDay-view tr.fc-minor td,
.fc-timeGrid3Days-view tr.fc-minor td,
.fc-timeGrid4Days-view tr.fc-minor td,
.fc-timeGridWeek-view tr.fc-minor td {
	border: 0;
}

/* permanent axis with: not responsive */
.fc-axis {
	width: 36px !important;
}

.fc .fc-axis {
	vertical-align: top;
}

.fc-slats .fc-axis {
	text-align: center;
	border: 0px !important;
	text-transform: uppercase;
	/* transform: translate(0px, -50%); */
	transform: translate(0px, -0.7em);
	background: #fff;
	z-index: 2;
	position: relative;
}

.fc-timeGridDay-view tr td,
.fc-timeGrid3Days-view tr td,
.fc-timeGrid4Days-view tr td,
.fc-timeGridWeek-view tr td {
	padding: 0px;
	background: none;
	border: #fff;
	z-index: 2;
	position: relative;
}

.fc-slats {
	margin-top: 5px !important;
	margin-bottom: 100px;
}

.fc-divider {
	display: none !important;
}

.fc-scroller {
	overflow-x: hidden !important;
	overflow-y: scroll !important;
	bottom: auto;
}

.fc-head td {
	border-top: none !important;
}

.fc-head-container td {
	border-top: none !important;
}

.table-bordered {
	border: none !important;
}

.fc-head .table-bordered td {
	border: 0.5px solid #eef2f8;
}

.fc-head .table-bordered th {
	border: none;
}

.fc-day-header {
	border-top: none !important;
	/* border-bottom: 0.5px solid #eef2f8 !important; */
	border-right: 0.5px solid #eef2f8 !important;
	border-bottom: none !important;
}

.fc-day-header span {
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: #bcbdbf;
}

.fc td {
	border-style: solid;
	border-width: 1px;
	padding: 0;
	vertical-align: top;
	border-color: #eef2f8;
}

.add-scrollers {
	overflow-x: hidden !important;
	overflow-y: scroll !important;
	height: 100vh !important;
}

.fc-event.fc-selected:after {
	content: '';
	position: absolute;
	z-index: 1;
	top: -1px;
	right: -1px;
	bottom: -1px;
	left: -1px;
	background: #fff !important;
	opacity: 0.25;
}

.titleEvent {
	height: 20px;
}

/* highlight today on month view */
.fc-dayGridMonth-view td.fc-day.fc-today.alert.alert-info {
	background-color: rgba(142, 96, 242, 0.3) !important;
}

/* selected indicator */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
	left: 0;
	right: 0;
	bottom: 0;
	height: 8px;
	overflow: hidden;
	line-height: 8px;
	font-size: 16px;
	font-weight: 900;
	font-family: monospace;
	text-align: center;
	cursor: s-resize;
}

/* resize indicator container */
.fc-time-grid-event.fc-selected .fc-resizer {
	border-radius: 50%;
	border-width: 1px;
	width: 12px;
	height: 12px;
	border-style: solid;
	border-color: inherit;
	background: rgb(142, 74, 222);
	left: 50%;
	margin-left: -6px;
	bottom: -9px;
	font-size: 16px;
	font-weight: 900;
	overflow: visible !important;
}

/* touch area */
.fc-event.fc-selected .fc-resizer:before {
	content: '';
	position: absolute;
	z-index: 9999;
	top: 100%;
	left: 50%;
	width: 50px;
	height: 35px;
	margin-left: -26px;
}

.fc-timeGridDay-view .fc-event.fc-selected .fc-resizer:before {
	content: '';
	position: absolute;
	z-index: 9999;
	top: 100%;
	left: 50%;
	width: 200px;
	height: 35px;
	margin-left: -100px;
}
.fc-timeGrid3Days-view .fc-event.fc-selected .fc-resizer:before {
	content: '';
	position: absolute;
	z-index: 9999;
	top: 100%;
	left: 50%;
	width: 100px;
	height: 35px;
	margin-left: -50px;
}
.fc-timeGrid4Days-view .fc-event.fc-selected .fc-resizer:before {
	content: '';
	position: absolute;
	z-index: 9999;
	top: 100%;
	left: 50%;
	width: 80px;
	height: 35px;
	margin-left: -40px;
}

/* resize cursor */
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
	content: '';
	display: flex;
	justify-content: center;
	align-items: center;
	height: inherit;
	margin-top: 1px;
	color: darkgrey;
}

/* now indicator line */
.fc-now-indicator {
	border: 0.5px solid #3a465b;
	opacity: 1;
}

.fc-event.fc-selected {
	border: 2px solid rgba(142, 74, 222, 1) !important;
}

.fc-event.fc-dragging:not(.fc-selected) {
	opacity: 0.75;
	border: 2px solid rgba(90, 77, 104, 0.5) !important;
}

/* EVENT */
.fc-event {
	border: none;
	font-size: 13px;
	margin-right: 2px;
}

.fc-dayGridMonth-view .fc-event {
	height: 16px;
	line-height: 1.12;
	margin-top: 1px !important;
	margin-bottom: 2px !important;
	overflow: hidden;
}
.fc-dayGridMonth-view .fc-event .fc-content {
	padding: 0px;
	padding-left: 1px;
}
.fc-dayGridMonth-view .fc-event .fc-title {
	font-size: 10px;
	line-height: 15px;
	font-weight: normal;
	overflow: hidden;
}

/* except for virtual event */
.fc-event[event-id='-1'] {
	border: 1px solid rgba(142, 74, 222, 1);
}

/* MIRROR */
.fc-mirror {
	background-color: #fff;
	opacity: 1;
	border-radius: 3px;
	border: 1px solid rgb(142, 74, 222);
	box-shadow: 1px 1px 4px #888888;
	display: flex;
}
/* .fc-mirror::before {
	content: '';
	width: 0px;
	height: 0px;
	border: 6px solid rgb(142, 74, 222);
	border-radius: 50%;
	left: 50%;
	margin-left: -6px;
	position: absolute;
	top: -6px;
	cursor: n-resize;
} */
.fc-mirror::after {
	content: '';
	width: 0px;
	height: 0px;
	border: 6px solid rgb(142, 74, 222);
	border-radius: 50%;
	left: 50%;
	margin-left: -6px;
	position: absolute;
	bottom: -6px;
	cursor: s-resize;
}

.fc-time-grid {
	margin-top: -5px;
}

.wrapperText {
	font-size: 13px;
}

.wrapperText a {
	color: inherit;
}

.wrapperContent {
	white-space: normal;
	word-wrap: break-word;
}

.fc-hue {
	height: 100px;
}

/* TIME GRID HORIZONTAL LINE */
tr[data-time*='00:00'],
tr[data-time*='15:00'],
tr[data-time*='30:00'],
tr[data-time*='45:00'] > td:not(:first-child) {
	border-top: 1px solid #ebeff3 !important;
}
tr[data-time*='00:00:00'] td {
	transform: translate(0px, -0.075em) !important;
}

.fc-timeGridWeek-view td.fc-day.fc-today.alert.alert-info {
	background-color: rgba(255, 187, 0, 0.1) !important;
	border-top: none !important;
}

.fc-timeGrid3Days-view td.fc-day.fc-today.alert.alert-info {
	background-color: rgba(255, 187, 0, 0.1) !important;
	border-top: none !important;
}

.fc-timeGrid4Days-view td.fc-day.fc-today.alert.alert-info {
	background-color: rgba(255, 187, 0, 0.1) !important;
	border-top: none !important;
}
/* TIME GRID HORIZONTAL LINE */

/* Header left */
.btn-change-mode {
	align-items: center;
	justify-content: center;
}

/* ICON ON TILES */
.recurr-block {
	width: 12px;
	height: 16px;
	display: block;
	margin-top: -2px;
	padding-bottom: 2px;
}

/* for month view */
.recurr-block-month {
	width: 12px;
	height: 16px;
	display: inline-block;
	float: left;
	margin-right: 3px;
}

.lock-block {
	position: absolute;
	width: 10px;
	height: 14px;
	top: 0px;
	right: 2px;
}

.lock-block-padding {
	position: absolute;
	width: 10px;
	height: 14px;
	top: 15px;
	right: 1px;
}

.warning-block {
	position: absolute;
	top: 2px;
	right: 0px;
}

.warning-bottom {
	top: 16px;
}

.warning-left {
	/* right: 14px; */
	/* move to bottom of lock icon to avoid cover text */
	top: 16px;
}

.triangle-up {
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 12px solid #fa353a;
}

.triangle-content {
	position: absolute;
	left: 4.2px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	font-size: x-small;
	font-weight: 900;
	color: #fff;
}
/* ICON ON TILES */

.loading-icon {
	position: absolute;
	width: 15px;
	height: 15px;
	right: 3px;
	top: 1px;
	display: none;
}

/* more cell */
.fc-more-cell div {
	background-color: red;
	height: 16px;
	background-color: #ebeff3;
	margin-left: 2px;
	margin-right: 2px;
	border-radius: 4px;
	justify-content: center;
	align-items: center;
}

.fc-more {
	white-space: nowrap;
	display: block;
	font-size: 10px !important;
	color: #7a83a6;
	line-height: 15px;
	text-align: center;
}

span.fc-close.fa.fa-times::before {
	content: 'X';
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bold;
}

.fc-allow-mouse-resize {
	/* preparing for touch effect */
	/* border: 2px solid rgb(142, 74, 222) !important; */
}

/* overwrite to expand close button */
.fa-times:before {
	content: '\f00d';
	padding: 0px 10px 0px 20px;
}

/* month header */
.fc-day-header {
	height: 26px;
	border-left: 1px solid #eef2f8 !important;
	border-right: 1px solid #eef2f8 !important;
}
.table-bordered thead td {
	border: none !important;
}
td.fc-day {
	border-top: none;
}
td.fc-day.fc-today {
	border-top: none;
}

.time-window-warning-month {
	float: left;
	width: 20px;
	height: 16px;
	display: block;
	margin-top: -3px;
	margin-left: -5px;
	padding-bottom: 2px;
}

.time-window-warning {
	float: left;
	width: 22px;
	height: 16px;
	display: block;
	margin-top: -2px;
	margin-left: -2px;
	padding-bottom: 2px;
}

.unread-sms {
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: #00aadd;
	position: absolute;
	right: 0px;
	top: 3px;
}
